@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@font-face {
  font-family: 'AvantGarde';
  src: local('AVAMTM'), url(./AVANTN.ttf) format('truetype');
}


html {
	font-family: 'AvantGarde';
	overflow: hidden;
}

body {
	margin: 0;
	font-family: 'AvantGarde';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
} */


@font-face {
    font-family: 'Garamond';
    font-style: normal;
    font-weight: normal;
    src: local('Garamond'), url('./assets/EBGaramond-VariableFont_wght.ttf') format('woff');
    }

html {
	font-family: 'Garamond';
	overflow: hidden;
}

body {
	margin: 0;
	font-family: 'Garamond';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
} 